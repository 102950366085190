<template>
  <v-container class="fill-height pa-0" fluid dark>
    <v-card
      class="transparent elevation-0 pa-0 mx-auto"
      :width="$vuetify.breakpoint.smAndDown ? 400 : 600"
    >
      <v-img :src="require('@/assets/logo_toro_white.svg')" height="183" contain />
      <v-card-text v-if="!state.authentication.loading" class="pb-0">
        <v-form v-model="self.form_singin" ref="form">
          <v-text-field
            v-bind:label="$t('singIn.email')"
            :value="state.authentication.credentials.email"
            @input="
              actions.updateAttribute({ key: 'authentication.credentials.email', value: $event })
            "
            :rules="[
              (v) => !!v || $t('singIn.emailRulesRequired'),
              (v) => emailRegex.test(v) || $t('singIn.emailRulesInvalid'),
            ]"
            dark
            clearable
            outlined
            required
          ></v-text-field>
          <v-text-field
            v-if="!state.authentication.credentials.forgotten"
            v-bind:label="$t('singIn.password')"
            v-model="state.authentication.credentials.password"
            v-bind:hint="$t('singIn.passwordRulesMinLength')"
            :rules="[
              (v) => !!v || $t('singIn.passwordRulesRequired'),
              (v) => v.length >= 8 || $t('singIn.passwordRulesMinLength'),
            ]"
            min="8"
            :append-icon="self.password_visibility ? 'visibility' : 'visibility_off'"
            @click:append="self.password_visibility = !self.password_visibility"
            :type="self.password_visibility ? 'password' : 'text'"
            dark
            clearable
            outlined
            required
          ></v-text-field>
          <v-alert
            v-if="state.authentication.message"
            v-model="state.authentication.error"
            type="error"
            border="left"
            elevation="0"
            colored-border
            text
          >
            {{ $t(`singIn.${state.authentication.message}`) }}
          </v-alert>
        </v-form>
      </v-card-text>
      <v-card-text
        v-if="state.authentication.recovery && state.authentication.credentials.forgotten"
        class="white--text"
      >
        {{ $t('singIn.recovery', { email: state.authentication.credentials.email }) }}
      </v-card-text>
      <v-card-text
        v-if="
          state.authentication.credentials.forgotten &&
          !state.authentication.loading &&
          !state.authentication.recovery
        "
        class="white--text pt-0 pb-6 font-weight-light"
      >
        {{ $t('singIn.emailToLinkRecovery') }}
      </v-card-text>
      <v-card-actions class="pt-0 mb-5 pr-4 pl-4" style="margin-top: -25px">
        <v-spacer></v-spacer>
        <v-btn
          v-if="!state.authentication.credentials.forgotten && !state.authentication.loading"
          class="elevation-0 font-weight-regular transparent text-capitalize white--text"
          @click="state.authentication.credentials.forgotten = true"
          outlined
          dark
        >
          {{ $t('singIn.forgotten') }}
        </v-btn>
        <v-btn
          v-else-if="!state.authentication.loading"
          class="elevation-0 font-weight-normal transparent text-capitalize white--text"
          @click="state.authentication.credentials.forgotten = false"
          outlined
          dark
        >
          <v-icon></v-icon>
          {{ $t('singIn.singIn') }}
        </v-btn>
      </v-card-actions>
      <v-card-actions class="pr-4 pl-4">
        <v-spacer></v-spacer>
        <v-btn
          v-if="!state.authentication.loading && !state.authentication.credentials.forgotten"
          class="elevation-0 pr-0 pl-0 font-weight-normal"
          @click="validate() ? actions.singIn() : null"
          block
        >
          {{ $t('singIn.logIn') }}
        </v-btn>
        <v-btn
          v-if="state.authentication.credentials.forgotten"
          class="elevation-0 pr-0 pl-0 font-weight-normal"
          @click="validate() ? actions.singIn() : null"
          block
        >
          {{ $t('singIn.submit') }}
        </v-btn>
        <v-progress-circular
          v-if="state.authentication.loading"
          indeterminate
          width="1"
          size="100"
          color="grey"
        ></v-progress-circular>
        <v-spacer></v-spacer>
      </v-card-actions>
      <v-card-actions
        v-if="!state.authentication.credentials.forgotten && !state.authentication.loading"
      >
        <v-spacer></v-spacer>
        <a
          class="elevation-0 font-weight-light transparent text-noCapitalize black--text"
          href="https://toroadvertising.com/affiliates/signup"
          block
          plain
        >
          {{ $t('singIn.newAccount') }} <span class="white--text">{{ $t('singIn.singUp') }}</span>
        </a>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import { inject, reactive, ref } from '@vue/composition-api';

const emailRegex = /^\w+(?:[.-]\w+)*@\w+(?:[.-]\w+)*(\.\w{2,8})+$/;

export default {
  setup() {
    const { state, actions } = inject('Application');
    const self = reactive({
      form_singin: false,
      password_visibility: true,
    });
    const form = ref(null);
    const validate = () => {
      return form.value.validate();
    };
    return { state, actions, self, form, validate, emailRegex };
  },
};
</script>
<style scoped>
.text-noCapitalize {
  text-transform: none !important;
  text-decoration: none;
}
</style>
